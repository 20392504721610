<template>
    <div>
      <div class="flex flex-row items-center ml-0 gap-4">
        <vs-button
          @click="handleQuitEdit"
          class="rounded-lg"
          type="border"
          icon="arrow_back"
        >
          {{ this.$t('apps.campaigns.edit_quit') }}
        </vs-button>
        <div v-if="campaign_selected_languages.length !== 0" class="flex flex-row items-center gap-2">
          <feather-icon icon='AlertCircleIcon' svgClasses='h-6 w-6' class="text-warning"/>
          <span class="text-warning">{{ this.$t('apps.campaigns.edit_warning_attention') }}</span>
          <span>{{ this.$t('apps.campaigns.edit_warning_text') }}</span>
        </div>
      </div>

      <CampaignForm @campaign-scheduled="handleCampaignEdited" :isSkiApp="isSkiApp"/>

      <vs-popup :active.sync="modalOpen" :title="''" classContent="modalPhoto">
        <div class="flex flex-column items-center gap-4">
          <vs-icon icon="warning_amber" size="medium" color="danger"></vs-icon>
          <p class="text-center">{{ this.$t('apps.campaigns.edit_modal_body') }}</p>
          <div class="flex flex-column gap-2">
            <vs-button @click="handleLeaveWithoutSaving" type="border">{{ this.$t('apps.campaigns.edit_modal_button_leave') }}</vs-button>
            <vs-button @click="handleSaveAndLeave" color="success">{{ this.$t('apps.campaigns.edit_modal_button_save_and_leave') }}</vs-button>
          </div>
        </div>
      </vs-popup>

    </div>
</template>

<script>

import CampaignForm from '@/modules/Apps/Components/campaigns/form/CampaignForm'
import CampaignsService from '@/modules/Apps/Services/CampaignsService'
import {mapActions, mapGetters} from "vuex";
import AppsService from "@/modules/Apps/Services/AppsService";
import notifications from "@/modules/Shared/Mixins/notifications";

export default {
    name: 'edit-campaign',
    components: {
        CampaignForm
    },
    mixins: [notifications],
    data() {
      return {
        modalOpen: false,
        isSkiApp: false
      }
    },
    async created() {
      this.$store.commit('apps/RESET_CAMPAIGN')
      const languages = await AppsService.getLanguages(this.$route.params.uuid)
      this.$store.commit('apps/SET_LANGUAGES', languages)

      const campaign = await CampaignsService.getCampaign(this.$route.params.campaignUuid)
      await this.campaignToEditForm(campaign)

      const basicInfo = await this.loadBasicInfo()
      this.isSkiApp = Boolean(basicInfo.data.object.features.find(({ key }) => key === "is_ski_app"))
    },
    computed: {
      ...mapGetters('apps', ['campaign_payloads', 'campaign_selected_languages'])
    },
    methods: {
      ...mapActions('apps', ['campaignToEditForm']),
      goToCampaignHistory() {
        const path = `/apps/${this.$route.params.uuid}/campaigns`
        if (this.$route.path !== path) {
          this.$router.push({
            path,
            query: {
              section: 'history'
            }
          })
        }
      },
      handleCampaignEdited() {
        this.goToCampaignHistory()
        this.notifySuccess(this.$t('apps.campaigns.edit_notification_title'), this.$t('apps.campaigns.edit_notification_success'))
      },
      handleQuitEdit() {
        this.modalOpen = true
      },
      handleLeaveWithoutSaving() {
        this.goToCampaignHistory()
      },
      async handleSaveAndLeave() {
        await CampaignsService.editCampaign(this.$route.params.campaignUuid, this.campaign_payloads[0])
        this.goToCampaignHistory()
        this.notifySuccess(this.$t('apps.campaigns.edit_notification_title'), this.$t('apps.campaigns.edit_notification_success'))
      },
      async loadBasicInfo() {
        return await AppsService.basicAppInfo(this.$route.params.uuid)
      },
    }
}
</script>
