var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-row items-center ml-0 gap-4" },
        [
          _c(
            "vs-button",
            {
              staticClass: "rounded-lg",
              attrs: { type: "border", icon: "arrow_back" },
              on: { click: _vm.handleQuitEdit }
            },
            [_vm._v(" " + _vm._s(this.$t("apps.campaigns.edit_quit")) + " ")]
          ),
          _vm.campaign_selected_languages.length !== 0
            ? _c(
                "div",
                { staticClass: "flex flex-row items-center gap-2" },
                [
                  _c("feather-icon", {
                    staticClass: "text-warning",
                    attrs: { icon: "AlertCircleIcon", svgClasses: "h-6 w-6" }
                  }),
                  _c("span", { staticClass: "text-warning" }, [
                    _vm._v(
                      _vm._s(this.$t("apps.campaigns.edit_warning_attention"))
                    )
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(this.$t("apps.campaigns.edit_warning_text")))
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("CampaignForm", {
        attrs: { isSkiApp: _vm.isSkiApp },
        on: { "campaign-scheduled": _vm.handleCampaignEdited }
      }),
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.modalOpen,
            title: "",
            classContent: "modalPhoto"
          },
          on: {
            "update:active": function($event) {
              _vm.modalOpen = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-column items-center gap-4" },
            [
              _c("vs-icon", {
                attrs: {
                  icon: "warning_amber",
                  size: "medium",
                  color: "danger"
                }
              }),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(_vm._s(this.$t("apps.campaigns.edit_modal_body")))
              ]),
              _c(
                "div",
                { staticClass: "flex flex-column gap-2" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { type: "border" },
                      on: { click: _vm.handleLeaveWithoutSaving }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          this.$t("apps.campaigns.edit_modal_button_leave")
                        )
                      )
                    ]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "success" },
                      on: { click: _vm.handleSaveAndLeave }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          this.$t(
                            "apps.campaigns.edit_modal_button_save_and_leave"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }